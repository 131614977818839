import React from "react";
import Badge from "@atoms/Badge/Badge";
import Title from "@atoms/Title/Title";
import Icon from "@atoms/Icon/Icon";
import { IconShare } from "@atoms/Icon/list";
import { useTranslation } from "gatsby-plugin-react-i18next";
import "./AnnouncementBarV2.scss";
import { AnalyticsEventType } from "../../context/TrackingProvider";
import { useTracking } from "../../hooks";
const AnnouncementBarV2 = ({ title, href }) => {
	const { t } = useTranslation();
	const { track } = useTracking();

	return (
		<a
			className="AnnouncementBarV2"
			href={href}
			target="_blank"
			rel="noreferrer"
			onClick={() => {
				track({
					eventType: AnalyticsEventType.GButtonClick,
					data: {
						buttonName: "announcement_bar",
						sourceUrl: window.location.pathname,
						targetUrl: href
					},
				});
			}}
		>
			<div className="AnnouncementBarV2-wrapper">
				<Badge>{t("generic.new")}</Badge>
				<div className="AnnouncementBarV2-content">
					<Title tag="span" size="s" noMargin>
						{title}
					</Title>
					<Icon
						icon={<IconShare />}
						theme="white"
						size="xxs"
						variant="outlined"
					/>
				</div>
			</div>
		</a>
	);
};

export default AnnouncementBarV2;
