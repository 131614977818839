import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import useLocale from "@hooks/useLocale";
import { getOriginalLocale, isHomePage } from "@helpers/GlobalHelpers";
import { SUPPORTED_USER_LANG } from "@constants/common";
import { UserLang } from "@constants/common";
import AnnouncementBarV2 from "../AnnouncementBarV2";

const HomeAnnouncement = () => {
	const { currentLanguage } = useLocale();

	const dataQuery = useStaticQuery(graphql`
        query {
            aepsyCms {
                announcementsDE: announcements(locale: "de-CH", pagination: { limit: 100 }) {
                    content
                    locale
                    endAt
                    startAt
                    slugPattern
                    pages {
                        slug
                        locale
                    }
                }
				announcementsEN: announcements(locale: "en", pagination: { limit: 100 }) {
                    content
                    locale
                    endAt
                    startAt
                    slugPattern
                    pages {
                        slug
                        locale
                    }
                }
				announcementsFR: announcements(locale: "fr-CH", pagination: { limit: 100 }) {
                    content
                    locale
                    endAt
                    startAt
                    slugPattern
                    pages {
                        slug
                        locale
                    }
                }
				announcementsIT: announcements(locale: "it", pagination: { limit: 100 }) {
                    content
                    locale
                    endAt
                    startAt
                    slugPattern
                    pages {
                        slug
                        locale
                    }
                }
            }
        }
    `);

	const getCurrentPageSlug = () => {
		const pathNames =
			typeof window !== "undefined" ? window.location.pathname.split("/") : [];
		return isHomePage()
			? "/"
			: pathNames.find(
					(item: string) => ![...SUPPORTED_USER_LANG, "/", ""].includes(item),
				);
	};

	const getAnnouncementByLocale = (locale: string) => {
		switch (locale) {
			case UserLang.German:
				return dataQuery?.aepsyCms?.announcementsDE;
			case UserLang.English:
				return dataQuery?.aepsyCms?.announcementsEN;
			case UserLang.French:
				return dataQuery?.aepsyCms?.announcementsFR;
			case UserLang.Italian:
				return dataQuery?.aepsyCms?.announcementsIT;
		}
	};

	const announcement = (() => {
		if (dataQuery) {
			const announcementByLocale = getAnnouncementByLocale(currentLanguage);
			for (const announcementByLang of announcementByLocale) {
				if (getOriginalLocale(announcementByLang.locale) === currentLanguage) {
					const {
						pages: pagesData,
						slugPattern,
						endAt,
						startAt,
						content,
					} = announcementByLang;

					const currentPageSlug = getCurrentPageSlug();

					const today = new Date();
					const isUnexpired =
						today >= new Date(startAt) && today <= new Date(endAt);

					if (
						slugPattern?.split(",").includes(currentPageSlug) &&
						isUnexpired
					) {
						return content;
					}

					const announcementBySlug = pagesData.find(
						(item) =>
							typeof window !== "undefined" &&
							item.slug.includes(currentPageSlug),
					);

					if (announcementBySlug && isUnexpired) {
						return content;
					}
				}
			}
		}
	})();

	if (announcement) {
		return (
			<AnnouncementBarV2
				title={announcement}
				href={`${process.env.GATSBY_AEPSY_B2B_URL}/${currentLanguage}`}
			/>
		);
	}
	return <></>;
};

export default HomeAnnouncement;
