import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "@components/layout";
import Seo from "@components/seo";
import CMSHeaderMinimal from "@components/HeaderMinimal/HeaderMinimalV2";
import HeaderNav from "@components/HeaderNavV2/HeaderNav";
import {
	ChapterUSP,
	SocialProof,
	ProviderList,
	ChapterPricing,
	RelatedContentWithImg,
	CommonActionButton,
	GenericIcon,
	UspBanners,
	ChapterEntries,
	//PartnerAndInsuranceCompany,
} from "@components/CMSComponents";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "@atoms/Button/Button";
import Section from "@atoms/Section/Section";
import TitleGroup from "@atoms/TitleGroup/TitleGroup";
import VideoBackgroundWithContent from "@components/VideoBackgroundWithContent";
import Footer from "@components/Footer/Footer";
import KeyFeaturesSlider from "@components/KeyFeaturesSlider";
import SingleAccordion from "@components/SingleAccordion/SingleAccordion";
import { UserLang } from "@constants/common";
import Module from "@atoms/Module/Module";
import Title from "@atoms/Title/Title";
import Text from "@atoms/Text/Text";
import "./home.scss";
import useLocale from "../hooks/useLocale";
import LanguageNotSupported from "../components/LanguageNotSupported/LanguageNotSupported";
import GetStartedSteps from "../components/CMSComponents/GetStartedSteps";
import { withLocale, withTracking } from "@hocs";
import HomeAnnouncement from "../components/HomeAnnouncement";
import useTracking from "../hooks/useTracking";
export const query = graphql`
	fragment B2CBannerFields on AEPSYCMS_CommonComponent {
		name
		component {
		... on AEPSYCMS_ComponentAepsyUiModule {
			buttons {
				content
				internalLink {
					b2bPage {
						locale
						slug
					}
				}
			}
			media {
				src {
					url
				}
				breakpoint
			}
			texts {
				content
				}
			}
		}
	}
    fragment HomeField on AEPSYCMS_Homepage {
        seoTitle
        seoDescription
        header {
            title
            subtitle
            ctaUrl
            ctaLabel
            secondaryCtaUrl
            secondaryCtaLabel
        }
        chapterEntries {
            title
            url
            images {
                media
                src {
                    url
                    urlSharp {
                        childImageSharp {
                            fluid(
                                maxWidth: 250
                                toFormat: WEBP
                                webpQuality: 100
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        uspBanners {
            button {
                label
            }
            icon
        }
        socialProof {
            author
            quote
            comments {
                text
            }
            audio {
                url
            }
            audioMoreInfo {
                component {
                    ... on AEPSYCMS_ComponentCommonCardInfoList {
                        title
                        content
                        image {
                            url
                            urlSharp {
                                childImageSharp {
                                    fluid(
                                        maxWidth: 150
                                        toFormat: WEBP
                                    ) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        providersSlider {
            ...ProvidersSlider
        }
        stepByStep {
            title
            subtitle
            list {
                title
                subtitle
                media {
                    url
                    ext
                }
            }
            ctaUrl
            ctaLabel
        }
        chapterUsp {
            ...ChapterUsp
        }
        pricing {
            title
            subtitle
            pricingTitle
            pricingSubtitle
            secondaryCtaLabel
            secondaryCtaUrl
            list {
                text
            }
            ctaUrl
            ctaLabel
            images {
                media
                src {
                    url
                    urlSharp {
                        childImageSharp {
                            fluid(
                                maxWidth: 300
                                toFormat: WEBP
                                webpQuality: 90
                                fit: CONTAIN
                            ) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        faq {
            key
            qar {
                id
                question
                answer
            }
            title
            subtitle
        }
        aepsyUsp {
            component {
                ... on AEPSYCMS_ComponentCommonAepsyUsp {
                    title
                    subtitle
                    content
                    icon
                }
            }
        }
        partners {
            title
            subtitle
            text
            images {
                url
            }
            companies {
                label
            }
        }
        relatedContent {
            title
            components {
                component {
                    ... on AEPSYCMS_ComponentAtomsImageButton {
                        button {
                            label
                            to
                        }
                        images {
                            media
                            src {
                                url
                                urlSharp {
                                    childImageSharp {
                                        fluid(
                                            maxWidth: 250
                                            toFormat: WEBP
                                            webpQuality: 90
                                            fit: CONTAIN
                                        ) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        aepsyCms {
            homepageDE: homepage(locale: "de-CH") {
                ...HomeField
            }
            homepageEN: homepage(locale: "en") {
                ...HomeField
            }
            homepageFR: homepage(locale: "fr-CH") {
                ...HomeField
            }
            homepageIT: homepage(locale: "it") {
                ...HomeField
            }
			b2bBannerDE: commonComponents(filters: {name: {
				eq: "B2C - Explore B2B banner"
			}} , locale: "de-CH") {
				...B2CBannerFields
			}
			b2bBannerEN: commonComponents(filters: {name: {
				eq: "B2C - Explore B2B banner"
			}} , locale: "en") {
				...B2CBannerFields
			}
			b2bBannerFR: commonComponents(filters: {name: {
				eq: "B2C - Explore B2B banner"
			}} , locale: "fr-CH") {
				...B2CBannerFields
			}
			b2bBannerIT: commonComponents(filters: {name: {
				eq: "B2C - Explore B2B banner"
			}} , locale: "it") {
				...B2CBannerFields
			}
        }
    }
`;
const HomePage = ({ data: graphqlData }) => {
	const { trackPageView } = useTracking();
	const { currentLanguage, routeCmsLocaleToAppLocale } = useLocale();

	const { homepage, b2bBanner } = (() => {
		const {
			aepsyCms: {
				homepageDE,
				homepageEN,
				homepageFR,
				homepageIT,
				b2bBannerDE,
				b2bBannerEN,
				b2bBannerFR,
				b2bBannerIT,
			},
		} = graphqlData;
		switch (currentLanguage) {
			case UserLang.English:
				return {
					homepage: homepageEN,
					b2bBanner: b2bBannerEN[0]?.component?.[0],
				};
			case UserLang.French:
				return {
					homepage: homepageFR,
					b2bBanner: b2bBannerFR[0]?.component?.[0],
				};
			case UserLang.Italian:
				return {
					homepage: homepageIT,
					b2bBanner: b2bBannerIT[0]?.component?.[0],
				};
			default:
				return {
					homepage: homepageDE,
					b2bBanner: b2bBannerDE[0]?.component?.[0],
				};
		}
	})();

	const renderHeaderBtnSecondary = (
		secondaryCtaLabel?: string,
		secondaryCtaUrl?: string,
	) => {
		if (!secondaryCtaLabel || !secondaryCtaUrl) return <></>;

		return (
			<AnchorLink offset={() => 40} href={secondaryCtaUrl}>
				<Button
					variant="naked"
					theme="white"
					label={header.secondaryCtaLabel}
					align="center"
					size="m"
				/>
			</AnchorLink>
		);
	};

	useEffect(() => {
		trackPageView();
	}, []);

	if (!homepage) {
		return (
			<Layout>
				<div className="global_theme-green HomePage">
					<Section theme="dark">
						<HeaderNav theme="white" />
						<CMSHeaderMinimal title="" subtitle="" spacing="xs" />
					</Section>
					<Section spacingBottom="m">
						<LanguageNotSupported />
					</Section>
					<Footer />
				</div>
			</Layout>
		);
	}

	const {
		seoTitle,
		seoDescription,
		header,
		socialProof,
		providersSlider,
		stepByStep,
		chapterUsp,
		pricing,
		faq,
		aepsyUsp,
		uspBanners,
		chapterEntries,
		partners,
		relatedContent,
	} = homepage;

	const pricingDesktopImage = pricing?.images.find(
		(item: any) => item.media === "desktop",
	);
	const pricingMobileImage = pricing?.images.find(
		(item: any) => item.media === "mobile",
	);

	return (
		<Layout>
			<Seo title={seoTitle} description={seoDescription} />
			<div className="global_theme-green HomePage">
				<Section theme="dark">
					<HeaderNav theme="white" />

					<CMSHeaderMinimal
						title={header.title}
						subtitle={header.subtitle}
						spacing="xs"
						btnPrimary={
							<CommonActionButton
								cta={{
									ctaLabel: header.ctaLabel,
									ctaUrl: header.ctaUrl,
								}}
							/>
						}
						topContent={<HomeAnnouncement />}
					/>
					<div
						className="HomePage--headerInner"
						id={
							header?.secondaryCtaUrl?.includes("#")
								? header?.secondaryCtaUrl?.replace("#", "")
								: "ChapterPage"
						}
					>
						{chapterEntries && <ChapterEntries list={chapterEntries} />}
						<div className="g_show_only_mobile HeaderMinimalV2--mainButtons">
							<div className="HeaderMinimal--btnPrimary">
								<CommonActionButton
									cta={{
										ctaLabel: header.ctaLabel,
										ctaUrl: header.ctaUrl,
									}}
								/>
							</div>
							<div>
								{renderHeaderBtnSecondary(
									header.secondaryCtaLabel,
									header.secondaryCtaUrl,
								)}
							</div>
						</div>
						{uspBanners && (
							<Section spacingTop="s">
								<UspBanners list={uspBanners} />
							</Section>
						)}

						{socialProof && (
							<SocialProof
								{...socialProof}
								audioUrl={socialProof.audio?.url}
								audioMoreInfo={socialProof.audioMoreInfo}
							/>
						)}
					</div>
				</Section>
				<div>
					<>
						{providersSlider && <ProviderList {...providersSlider} />}
						{chapterUsp && (
							<>
								<Section container="large">
									<TitleGroup
										align="desktopCenter"
										title={chapterUsp.title}
										subtitle={chapterUsp.subtitle}
									/>
								</Section>
								<Section spacingTop="s" spacingBottom="s">
									<ChapterUSP
										uspList={chapterUsp.list}
										cta={{
											ctaLabel: chapterUsp.ctaLabel,
											ctaUrl: chapterUsp.ctaUrl,
										}}
									/>
								</Section>
							</>
						)}
					</>
					<>
						{stepByStep && (
							<Section spacingBottom="m">
								<GetStartedSteps {...stepByStep} />
							</Section>
						)}
						{pricing && (
							<ChapterPricing
								{...pricing}
								desktopImage={
									pricingDesktopImage ? pricingDesktopImage.src?.url : undefined
								}
								desktopSharpImage={
									pricingDesktopImage
										? pricingDesktopImage.src?.urlSharp
										: undefined
								}
								mobileImage={
									pricingMobileImage ? pricingMobileImage.src?.url : undefined
								}
								mobileSharpImage={
									pricingDesktopImage
										? pricingDesktopImage.src?.urlSharp
										: undefined
								}
							/>
						)}
						{b2bBanner && (
							<Section spacingTop="l" spacingBottom="m" container="large">
								<div className="b2bBanner">
									<Module>
										<div className="b2bBanner--content">
											<div className="b2bBanner--leftContent">
												<img
													alt="aepsy b2b banner"
													src={
														b2bBanner?.media?.find(
															(media) => media.breakpoint === "lg",
														)?.src?.url
													}
													className="g_hide_mobile"
												/>
												<img
													alt="aepsy b2b banner"
													src={
														b2bBanner?.media?.find(
															(media) => media.breakpoint === "sm",
														)?.src?.url
													}
													className="g_show_only_mobile"
												/>
											</div>
											<div className="b2bBanner--rightContent">
												<Title size="l" className="mb-4">
													{b2bBanner?.texts?.[0]?.content}
												</Title>
												<Text size="s">{b2bBanner?.texts?.[1]?.content}</Text>
												<a
													href={`${process.env.GATSBY_AEPSY_B2B_URL}/${routeCmsLocaleToAppLocale(b2bBanner?.buttons?.[0]?.internalLink?.b2bPage?.locale)}`}
												>
													<Button
														variant="inline"
														theme="primary"
														label={b2bBanner?.buttons?.[0]?.content}
														iconArrow
													/>
												</a>
											</div>
										</div>
									</Module>
								</div>
							</Section>
						)}
						{/* Not ready for release yet (15.dec 2023) */}
						{/* {partners && (
                                <Section spacingTop="l" spacingBottom="m">
                                    <PartnerAndInsuranceCompany {...partners} />
                                </Section>
                            )} */}
					</>
					<>
						{aepsyUsp && (
							<div className="HomePage--KeyFeaturesSlider">
								<KeyFeaturesSlider
									list={aepsyUsp?.component?.map(
										({ title, subtitle, content, icon }: any) => ({
											title,
											subtitle,
											text: content,
											icon: <GenericIcon icon={icon} />,
										}),
									)}
								/>
							</div>
						)}

						{relatedContent && <RelatedContentWithImg {...relatedContent} />}
					</>

					{faq ? (
						<Section container="short" spacingTop="m">
							<TitleGroup
								title={faq.title}
								subtitle={faq.subtitle}
								align="desktopCenter"
							/>
							<Section spacing="s">
								<SingleAccordion
									data={faq.qar.map((item: any) => ({
										...item,
										isMarkdown: true,
									}))}
									comingFrom="chapter"
								/>
							</Section>
						</Section>
					) : null}
				</div>
				<Section container="large" spacingBottom="m" spacingTop="s">
					<VideoBackgroundWithContent />
				</Section>
				<Footer />
			</div>
		</Layout>
	);
};

export default withTracking(withLocale(HomePage));
